import React from "react";
import { Routes, Route } from "react-router-dom";
import Author from "./pages/author";
import Home from "./pages/home";
import Orientation from "./pages/orientation";
import Reprints from "./pages/reprints";
import Translations from "./pages/translations";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="author" element={<Author />} />
      <Route path="reprints" element={<Reprints />} />
      <Route path="translations" element={<Translations />} />
      <Route path="orientation" element={<Orientation />} />
    </Routes>
  );
};
export default Router;
