import React from "react";
import LogoPng from "../../assets/images/logo.svg";
export const BookMark = () => {
  return (
    <div className="bookmark">
          <h1>The Anatomy <span>of</span> Awakening</h1>
      
      <div id="book-mark-container">
      <img
        src={LogoPng}
        alt="Gosingārāma"
      />
      <div className="container-text">
      <span class="bqstart">“</span>
   <p className="quoteText">
          When conventional reality collapses then the truth is seen as it is:{" "}
          <span>dhātu, āyatana, khandha</span>. This is, and always has been,
          the timelessly true nature of things that, once known and seen,
          inexorably leads out from <span>saṃsāra</span>
        </p>
        {/* </blockquote> */}
        {/* <span class="bqend">”</span> */}
      </div>
      </div>
    </div>
  );
};