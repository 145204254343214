import { Popover } from "@mui/material";
import React from "react";
import { Info, MouseOverPopover } from "../../components/Info/Info";
const Orientation = () => {
  return (
    <div className="App">
      <div className="article">
        <h1>Dhamma-vinaya and the Theravāda Commentarial Tradition</h1>
        {/* <h1>Orientation</h1> */}
        <p>
          <span className="dropcap">I</span>
          <span className="sc">t has been requested</span> that the author of{" "}
          <span>The Anatomy of Awakening </span>
          expand upon a recent message to a supporter in response to the
          incomprehension displayed by monks in a forest tradition monastery in
          Sri Lanka (regarding the usage of some of the technical terminology
          employed in these works) and, by extension, by those in traditional
          Theravāda countries, monks and laity, who are schooled in an
          unquestioning acceptance of the, sometimes quite unacceptable,
          theories introduced by the commentators of the Theravāda school to the
          well-taught teachings of the Lord Buddha. Although being reluctant to
          enter into a polemical discourse, the necessity for such a preemptive
          article has now become difficult to ignore, and moved by a feeling of
          concern for fellow Buddhists who could benefit, I have acquiesced to
          the request to write an orienting article for the consideration of
          potential skeptics and for inclusion in this website.
        </p>
        <p style={{ textIndent: 0 }}>
          <span className="sc">The important point</span> for all Buddhists to
          understand is that the Buddha’s own term for his teachings was{" "}
          <span>dhamma-vinaya:</span> and that is quite literally what was known
          as <span> buddha-vacana</span> during the Buddha’s lifetime. What
          became known as the <span>Abhidhamma</span> (and therefore also the
          Tipiṭaka classification) came hundreds of years later as a developed
          body of literature—although this branch of the Buddhist textual
          tradition likely had its roots in the <span>mātikā</span> lists
          already compiled in the Dīgha Nikāya. For our purposes then, this
          means that it is the Vinaya Vibhaṅga & Khandakas and the oldest works
          of the Sutta Piṭaka (Dīgha Nikāya, Majjhima Nikāya, Aṅguttara Nikāya,
          Saṃyutta Nikāya; and the Thera/Therī-gāthā, Udāna, Itivuttaka,
          Dhammapada & Sutta-nipāta from the Khuddaka Nikāya) that comprise the{" "}
          <span>dhamma-vinaya</span> that the Buddha instructed his disciples to
          take as their teacher once he was no longer{" "}
          <MouseOverPopover
            infoText={"Dīgha Nikāya 16, Mahāparinibbāna sutta."}
            reference={1}
          >
            present.
          </MouseOverPopover>
        </p>
        <p style={{ textIndent: 0 }}>
          {" "}
          Up until this point there should be little that is too controversial.
          Issues arise, however, as to how to interpret this body of knowledge
          and which are the reliable and accurate sources to take as guides—if
          any—to understand these well-taught teachings on any unclear points.
          Traditionally, that has been the role of commentaries (
          <span>aṭṭhakathā</span>) and sub-commentaries (<span>ṭīkā</span>) in
          Theravāda countries, and that is indeed their intended purpose.
        </p>
        <p style={{ textIndent: 0 }}>
          These commentaries are in fact a valuable historical record of the
          views of scholar monks from around 1,000 years after the
          <span> mahāparinibbāna</span>, when the commentaries were written
          down, and on certain points, where they seem to have inherited a
          traditional understanding of the circumstances surrounding incidents
          from the Buddha’s time and after, it is likely that they preserve
          traditional wisdom and received traditions that may be of interest to
          Historians. But as the recognised and definitive interpretation of{" "}
          <span>dhamma-vinaya </span>
          for establishing the correct view that is conducive to Dhamma
          penetration, they are quite clearly flawed for anyone approaching them
          without blind faith—and a lack of familiarity with their contents may
          be considered as an advantage for viewing Buddha-Dhamma with clear,
          untrammeled vision.
        </p>
        <p style={{ textIndent: 0 }}>
          To take just a few examples of where the commentaries have introduced
          theories that contradict and have had a deleterious effect on
          <span> dhamma-vinaya:</span> there is an important teaching in the{" "}
          <span>suttas</span> (i.e.
          <span> ‘dhamma’</span>) that wherever the noble eightfold path is
          found, there one will find the <span>four</span> types of noble{" "}
          <MouseOverPopover
            reference={2}
            infoText={
              <div className="info-text">
                Ibid. Also, ‘if in this system [monks] live the perfect life,
                then the world will not be bereft of <span>arahants’</span> (DN
                16).
              </div>
            }
          >
            disciples.
          </MouseOverPopover>{" "}
          The commentaries, however, introduce varied theories that the{" "}
          <span>sāsana</span> disappears by 1,000 year periods, and so by that
          standard, arahantship,{" "}
          <span>paṭisambhidā, vimutti, adhigama, magga/phala,</span> and{" "}
          <span>samādhi,</span> depending on the{" "}
          <MouseOverPopover
            infoText={
              <div className="info-text">
                Samantapāsādikā 1291 and Manorathapūraṇī IV 136f. A different
                classification is in the commentary to the Theragāthā (Th-a III
                89). Other commentaries have similar theories (The Anagatavaṃsa
                among others [e.g. Mp I 87, Mph 201–203, extract from the{" "}
                <span>Anag </span>
                commentary, JPTS pp. 33–36]). Interestingly, Burmese tradition
                holds an alternative theory in which the <span>
                  sāsana
                </span>{" "}
                declines by 500 year periods in two cycles. According to that
                theory, we are now at the beginning of a period in which the
                highest attainments are again possible. While this theory is
                more plausible (in that it is only recently that there are those
                known to have reputably attained to the{" "}
                <span>arahattadhamma</span>: Luang Pu Sao, Luang Pu Mun, Ven.
                Webu Sayādaw, Luang Ta Mahā Boowa, and Luang Por Chah, for
                example, among many others in these lineages up until the
                present day), the commentarial theories prevalent in scholarly
                circles in Sri Lanka and Thailand are of an uninterrupted
                disappearance (<span>antaradhāna</span>) of the{" "}
                <span>sāsana</span>. At present then, past the halfway mark of
                2,500 years, destruction of the <span>āsava</span> etc. would no
                longer be possible: so the overall import of this section
                pertains. (The 5,000 year <span>sāsana</span> theory is recorded
                in the Atthasālinī. This theory conflates the fate of{" "}
                <span>saddhamma </span>
                with that of the <span>sāsana</span>. The <span>saddhamma</span>{" "}
                only lasted <span>unadulterated </span>
                for 500 years <span>as the Buddha predicted</span> [Cv X.1, AN
                8.51], as
                <span> sutras</span> composed by disciples were first accepted
                as
                <span> buddha-vacana</span> during a Council in Northern India at
                that time: thus beginning the replacement process described in
                the ‘Drum Peg’ <span>sutta</span>, SN 20.7.)
              </div>
            }
            reference={3}
          >
            source,
          </MouseOverPopover>{" "}
          would all be impossible to realise at present if one were to take
          those theories as definitive. These theories are one of the reasons
          that the practice of meditation has been historically weak in
          Theravāda countries until recent times in the author’s understanding,
          as it is often at the root, consciously or unconsciously, of the
          defeatist attitude, common among scholar monks, that Dhamma practice
          (particularly that of a forest dwelling monk) is relatively fruitless
          at present and that a monk is better served by studying and service
          rather than tiring oneself out with arduous practice that cannot now,
          according to scholasticism, give rise to the highest attainments.
        </p>
        <p style={{ textIndent: 0 }}>
          This view, based on the theories introduced in the commentaries,
          contradicts the Lord Buddha’s teaching and shows little regard for the
          great compassion of the Buddha for future generations in establishing
          a <span>sāsana</span>. That difficult task was undertaken to give the
          greatest possible chance for the Buddha’s disciples to realise the
          paths and fruits of the Buddhist path in perpetuity. However, this
          commentarial theorising has been sufficient to undermine that noble
          aim by replacing it with the defeatist attitude of an academic
          approach to Dhamma, thereby showing little awareness or careful regard
          for the long-term effect of introducing such theories on the ability
          of future generations of practitioners to realise the{" "}
          <span>ariyamagga:</span> such concerns generally being outside the
          remit of scholars.
        </p>
        <p style={{ textIndent: 0 }}>
          Another theory that clearly contradicts <span>buddha-vacana</span> is
          the commentarial theory that the <span>magga</span> lasts a single{" "}
          <MouseOverPopover
            reference={4}
            infoText={
              "Atthasālinī (Ven. Buddhaghosa’s commentary to the Dhammasaṅgaṇī). See also Chap. 22 of the Visuddhimagga."
            }
          >
            mind-moment.
          </MouseOverPopover>{" "}
          This assertion is based on the broader commentarial theory of
          mind-moments (<span>citta-vīthi</span>) and the attempts to
          retrospectively shoehorn <span>dhamma</span> into that mould. Taking
          just this instance of the theory as it is applied to path attainment
          though, it is clearly mistaken. The word <span>magga</span> in Pāli
          means ‘path’. Etymologically, does a ‘path’ of a single mind-moment,
          measured in billionths of a second, make sense? In the
          <span> suttas,</span> those on the <span>sotapattimagga</span> are
          further divided into the
          <span> saddhānusārī</span> and <span>dhammānusārī</span> types, and
          these individuals are said to accept meal offerings, resort to
          suitable dwellings, keep the company of good friends, and to balance
          the{" "}
          <MouseOverPopover
            reference={5}
            infoText={
              "Aṅguttara Nikāya IV 215 and Majjhima Nikāya 70, Kīṭāgiri sutta."
            }
          >
            faculties.
          </MouseOverPopover>{" "}
          Such would be impossible according to the commentarial theory of a
          one-mind-moment
          <span> magga</span>. Instead, it is more reasonable to accept that
          while it is possible that <span>magga/phala</span> may be experienced
          almost simultaneously in some cases, they can also be separated by
          days, weeks, months, or years, with the only certainty given in the{" "}
          <span>suttas</span> being that the fruit of the respective path must
          be experienced at or before{" "}
          <MouseOverPopover infoText={"Saṃyutta Nikāya III 225."} reference={6}>
            death.
          </MouseOverPopover>{" "}
          Forest Meditation Masters who have experienced these states have
          unambiguously recorded experiences of <span>magga</span> and{" "}
          <span>phala</span> as being separated in{" "}
          <MouseOverPopover
            reference={7}
            infoText={
              <div className="info-text">
                For an example of such a text in the Thai forest tradition, see{" "}
                <span>Seeking Buddho</span>, Ajahn Anan Akiñcano, pp. 79–80.
              </div>
            }
          >
            time,
          </MouseOverPopover>{" "}
          and this in no way contradicts <span>saddhamma</span>. It only
          contradicts the late commentarial theory of an always-momentary
          ‘path’.
        </p>
        <p>
          These two examples of commentarial distortions of Dhamma were chosen
          because they impact the central concerns of a practitioner and are
          clearly mistaken. That being the case, why would a meditator take them
          as a reliable guide for understanding the true import of the Buddha’s
          teachings?
        </p>
        <p style={{ textIndent: 0 }}>
          Regarding the Vinaya, similar, even more obvious, flawed theories are
          introduced. Probably the foremost of these being that the Buddha was
          three times the height of a normal{" "}
          <MouseOverPopover
            infoText={
              <div className="info-text">
                Samantapāsādikā, <span>sanghādisesa</span> 6.
              </div>
            }
            reference={8}
          >
            man.
          </MouseOverPopover>{" "}
          This theory was presumably introduced to elevate the status of the
          Buddha in a way that would have been inspiring and impressive to
          medieval scholar monks. But it plays havoc with any rules in the{" "}
          <span>vinaya</span> based on measurements for those who would like to
          follow these rules as they were originally formulated: measurements
          being measured by <span>sugata</span> (arm) lengths, (hand) spans, and
          (finger) breadths. So the <span>vinaya</span> rules concerning the
          size of a dwelling, robe, bathing & sitting cloth, and the height of a
          bed make absolutely no sense if the Buddha (<span>sugata</span>) is
          giantized. The ‘allowable’ upper limits of a robe size, for example,
          being in the range of an average-sized house foundation by taking this
          standard.
        </p>
        <p style={{ textIndent: 0 }}>
          {" "}
          There are numerous instances in the <span>suttas</span> that show that
          the Buddha, while being tall and of graceful proportions with a golden
          complexion etc., could still be mistaken for another tall monk in the
          distance{" "}
          <MouseOverPopover
            infoText={
              <div className="info-text">
                Vinaya Vibhaṅga, <span>pācittiya</span> 92.
              </div>
            }
            reference={9}
          >
             (e.g. Ven. Nanda),
          </MouseOverPopover>{" "}
          needed to be pointed out to a King in the assembled{" "}
          <MouseOverPopover
            infoText={"Dīgha Nikāya 2, Sāmaññaphala sutta."}
            reference={10}
          >
            Saṅgha,
          </MouseOverPopover>{" "}
          was not recognised when staying overnight in the same dwelling as a
          travelling monk until giving a{" "}
          <MouseOverPopover
            reference={11}
            infoText={"Majjhima Nikāya 140, Dhātuvibhanga sutta."}
          >
            discourse,
          </MouseOverPopover>{" "}
          and could exchange robes with{" "}
          <MouseOverPopover infoText={"Saṃyutta Nikāya 16.11."} reference={12}>
            Ven. Mahākassapa
          </MouseOverPopover>{" "}
          among other examples—all situations which would be difficult to
          understand if the Buddha was of physically superhuman height.{" "}
        </p>
        <p>
          These examples should be sufficient to show that the theories
          introduced by the commentaries may be safely put aside as being
          incongruous in a definitive interpretive guide to{" "}
          <span>dhamma-vinaya</span>, as they quite often create, rather than
          clarify, confusion.
        </p>
        <p>
          Technicalities aside, another deficiency in commentarial sources is
          their sometimes ‘tone deaf ’ comments to any hint of a humane portrait
          of the Buddha or his disciples. There is a touching scene recounted in
          the <span>suttas</span> where the Buddha is in his 80<sup>th</sup>{" "}
          year and is warming his back in the setting sun. Ven. Ānanda comments
          that the Buddha’s physical appearance is aged now and not the same as
          before. The Buddha replies that that is so, his bodily state being
          comparable to that of a cart being held together with{" "}
          <MouseOverPopover
            reference={13}
            infoText={"Dīgha Nikāya 16, Mahāparinibbāna sutta."}
          >
            straps.
          </MouseOverPopover>{" "}
          The commentary to this <span>sutta </span>
          at this point interjects that, of course, that is not so and that what
          is being referred to is a wrinkle the size of a hair between the
          Buddha’s shoulder blades that only Ven. Ānanda could see—completely
          distorting this humane and touching portrait of the Buddha and his
          relationship with{" "}
          <MouseOverPopover
            infoText={"Sumaṇgalaviḷāsinī (DN 16)."}
            reference={14}
          >
            Ven. Ānanda.
          </MouseOverPopover>{" "}
          Such discordant ‘comments’ are frequent in commentarial literature,
          displaying a seeming inability to distinguish faith from wisdom and an
          imperviousness to the context and savour of <span>dhamma</span> (which
          has a single taste: <span>vimuttirasa</span>).
        </p>
        <p>
          With this understanding as a background, it should come as no surprise
          that the author of the works in this website pays little heed to
          commentarial theories even when utilising commentarial terms. This is
          a common practice among the teachers in the forest tradition with
          which the author is most familiar. So while a technical term like
          <span> bhavaṅga-citta</span> is utilised as a useful term to describe
          the unconscious aspects of mental experience, there being no specific
          word for the unconscious mind in the <span>suttas</span>, it is
          decoupled from the
          <span> citta-vīthi</span> theory to which the commentaries wed this
          term (similar to the way in which this theory’s application to the
          term <span>magga</span> does not fit the <span>sutta</span>{" "}
          description and should be discounted), such limitations on the term’s
          usage not being universally in accordance with the experiences of
          practitioners as well as being philosophically problematic for a
          cogent psychology of the{" "}
          <MouseOverPopover
            reference={15}
            infoText={
              <div className="info-text">
                See <span>The Buddhist Unconscious</span> by William S. Waldron,
                pp. 9–87 (RoutledgeCurzon, 2003) for historical context
                concerning this point: including a philosophical discussion
                regarding the earliest descriptions of ‘depth’ consciousness in
                the <span>suttas</span>, later Abhidhamma developments, and the
                introduction of the commentarial term
                <span> bhavaṅga-citta</span> in the developed Theravādin school,
                and the term’s limitations in that context, as a description of
                the unconscious. The relevant section of this work is highly
                contextualising and is recommended for anyone wishing to
                understand the early Buddhist historical roots, later
                philosophical development, and the metaphysics of the subliminal
                mind.
              </div>
            }
          >
            unconscious.
          </MouseOverPopover>{" "}
          This approach to technical terms is, in effect, an attempt to{" "}
          <span>rehabilitate </span>
          the useful technical terminology introduced in the commentaries so
          that it conforms with <span>saddhamma</span>, and meditators will not
          be led astray when understanding these terms in this way.
        </p>
        <p>
          While this approach to Buddha-Dhamma may seem novel to some, it shows,
          in truth, more respect for the Lord Buddha and for his teachings to
          place one’s faith in <span>dhamma-vinaya</span>, put those teachings
          into practice, and to only accept the schemata and views of other
          authorities once they have been experientially known to conform with
          the way things are. This is called ‘forest Dhamma’:{" "}
          <span>dhamma</span> that is experienced through practice (in the
          forest). The monks at the time of the Buddha practiced in this way,
          there being little in the way of commentarial literature to create
          preconceptions for practitioners during that early period. Dhamma
          penetration sees reality just as it is and has nothing to do with
          trying to recreate the theories that have been learned from books in
          one’s meditation practice.
        </p>
        <p>
          The Theravāda school can broadly be held as a seminal Buddhist
          tradition, as it has preserved <span>dhamma-vinaya</span> without
          changing it right up until the present day. The fact that the
          commentators of the Theravāda school felt compelled to theorise in
          their commentaries, even when those theories did not align well with
          the root text that they were commenting on, gives reason for
          confidence that they preserved those root texts faithfully,{" "}
          <span>especially</span> in cases where they have not been aptly
          explicated. And for this we can be truly grateful. But to
          unquestioningly accept commentarial explanations, theories, and views
          just because they were written down in books that have become an
          orthodox part of one’s tradition is unwise if they can be shown to be
          mistaken on crucial points of Dhamma.
        </p>
        <p>
          {" "}
          So the approach of the works offered here is to only accept that which
          accords with the knowledge that is gained through practice; namely, by
          sacrificing one’s life for Dhamma until one has seen that Dhamma for
          oneself. In other words, true Dhamma is perfectly expounded and is in
          complete agreement with insight into reality—the way things are. If it
          can be shown that explanations and Dhamma theories are{" "}
          <span>not</span> perfectly expounded, as in the examples in this
          article, then those sources are clearly not always a helpful guide for
          comprehending <span>buddha-vacana</span>. Once this is established,
          then the genuine insight and experiences of meditators become a{" "}
          <span> more </span>
          reliable guide to <span>saddhamma</span> than the ‘orthodox’
          commentaries written by scholars for that purpose: as deep insight
          arises from the same inexhaustible well of <span>saccadhamma</span>{" "}
          that is the source of all of the Buddha’s teachings. This recognition
          is basic to forest monks who are in the stream of this ancient lineage
          of practitioners.
        </p>
        <p>
          Therefore, occasionally technical terms (e.g.{" "}
          <span>bhavaṅga-citta</span>) in these works have consciously been used
          in ways that might not be familiar to those schooled in their
          commentarial usage. This is quite deliberate and is not a ‘mistake’,
          as the intention is to rehabilitate these terms (‘wash off the mud’,
          see below) from any unnecessary accretions so that they conform to a
          more phenomenologically accurate description of reality than their
          alternative explanation; and the reader is invited to keep an open
          mind, wholly devote themselves to ardently keeping the touch of the
          breath in mind and to penetrate Dhamma through that means before
          deciding what is and what is not ‘Dhamma’.
        </p>
        <p>
          An elephant that neglects to wash the mud off lotus roots before
          swallowing them can expect to suffer from indigestion. Similarly, a
          practitioner who ‘swallows’ explanations of Dhamma without careful
          examination (‘washing’), just because they are received tradition, can
          expect a case of ‘Dhamma indigestion’; that is to say, to be afflicted
          with the <span>kilesa</span> of conceited views and opinions...
        </p>

        <div className="quotation">
          <span>
            Phra Ajaan Nico (Bhikkhu Jotidhammo), February 15th 2023 (B.E. 2566)
          </span>
        </div>
        <div className="end-mark">
          <hr />
        </div>
        <ol className="foot-notes-section">
          <li>Dīgha Nikāya 16, Mahāparinibbāna sutta.</li>
          <li>
            {" "}
            Ibid. Also, ‘if in this system [monks] live the perfect life, then
            the world will not be bereft of <span>arahants’</span> (DN 16).
          </li>
          <li>
            {" "}
            Samantapāsādikā 1291 and Manorathapūraṇī IV 136f. A different
            classification is in the commentary to the Theragāthā (Th-a III 89).
            Other commentaries have similar theories (The Anagatavaṃsa among
            others [e.g. Mp I 87, Mph 201–203, extract from the{" "}
            <span>Anag </span>
            commentary, JPTS pp. 33–36]). Interestingly, Burmese tradition holds
            an alternative theory in which the <span>sāsana</span> declines by
            500 year periods in two cycles. According to that theory, we are now
            at the beginning of a period in which the highest attainments are
            again possible. While this theory is more plausible (in that it is
            only recently that there are those known to have reputably attained
            to the <span>arahattadhamma</span>: Luang Pu Sao, Luang Pu Mun, Ven.
            Webu Sayādaw, Luang Ta Mahā Boowa, and Luang Por Chah, for example,
            among many others in these lineages up until the present day), the
            commentarial theories prevalent in scholarly circles in Sri Lanka
            and Thailand are of an uninterrupted disappearance (
            <span>antaradhāna</span>) of the <span>sāsana</span>. At present
            then, past the halfway mark of 2,500 years, destruction of the{" "}
            <span>āsava</span> etc. would no longer be possible: so the overall
            import of this section pertains. (The 5,000 year <span>sāsana</span>{" "}
            theory is recorded in the Atthasālinī. This theory conflates the
            fate of <span>saddhamma </span>
            with that of the <span>sāsana</span>. The <span>saddhamma</span>{" "}
            only lasted <span>unadulterated </span>
            for 500 years <span>as the Buddha predicted</span> [Cv X.1, AN
            8.51], as
            <span> sutras</span> composed by disciples were first accepted as
            <span> buddha-vacana</span> during a Council in Northern India at
            that time: thus beginning the replacement process described in the
            ‘Drum Peg’ <span>sutta</span>, SN 20.7.)
          </li>
          <li>
            Atthasālinī (Ven. Buddhaghosa’s commentary to the Dhammasaṅgaṇī).
            See also Chap. 22 of the Visuddhimagga.
          </li>
          <li>
            Aṅguttara Nikāya IV 215 and Majjhima Nikāya 70, Kīṭāgiri sutta.
          </li>
          <li>Saṃyutta Nikāya III 225.</li>
          <li>
            {" "}
            For an example of such a text in the Thai forest tradition, see{" "}
            <span>Seeking Buddho</span>, Ajahn Anan Akiñcano, pp. 79–80.
          </li>
          <li>
            Samantapāsādikā, <span>sanghādisesa</span> 6.
          </li>
          <li>
            Vinaya Vibhaṅga, <span>pācittiya</span> 92.
          </li>
          <li>Dīgha Nikāya 2, Sāmaññaphala sutta.</li>
          <li>Majjhima Nikāya 140, Dhātuvibhanga sutta.</li>
          <li>Saṃyutta Nikāya 16.11.</li>
          <li>Dīgha Nikāya 16, Mahāparinibbāna sutta.</li>
          <li>Sumaṇgalaviḷāsinī (DN 16).</li>
          <li>
            {" "}
            See <span>The Buddhist Unconscious</span> by William S. Waldron, pp.
            9–87 (RoutledgeCurzon, 2003) for historical context concerning this
            point: including a philosophical discussion regarding the earliest
            descriptions of ‘depth’ consciousness in the <span>suttas</span>,
            later Abhidhamma developments, and the introduction of the
            commentarial term
            <span> bhavaṅga-citta</span> in the developed Theravādin school, and
            the term’s limitations in that context, as a description of the
            unconscious. The relevant section of this work is highly
            contextualising and is recommended for anyone wishing to understand
            the early Buddhist historical roots, later philosophical
            development, and the metaphysics of the subliminal mind.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Orientation;
