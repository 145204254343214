import React from "react";
import Ajaan from "../../assets/images/Ajaan.png";
const Author = () => {
  return (
    <div className="App">
      <div className="article">
        <h1>About the Author</h1>
        <img src={Ajaan} alt="Ajaan Nico" />
        <p>
          <span className="dropcap">A</span>
          <span className="sc">jaan Nico (Phra Jotidhammo)</span> was born in
          the year of the Water Ox on the 3<sup>rd</sup> of July, 1973.
          Initially ordaining in the Dhammayuttika Nikāya as a{" "}
          <span>sāmaṇera</span> with Tan Chao Khun Mahā Samai Sukhasumiddho
          (Phra Thepsīlabhorn) as Preceptor in 1995.
        </p>
        <p>
          Realising the need for more structured training, Ajaan Nico took
          <span> upasampadā</span> (higher ordination) on the 3<sup>rd</sup> of
          January, 1999, in the Wat Nong Pa Pong Lineage of Luang Por Chah
          Subhaddo (Phra Bodhiñāṇa).
        </p>
        <p>
          After five years training in a Wat Pa Pong branch monastery in
          Australia, Ajaan Nico sought out secluded places for practice in
          Australia, Sri Lanka, and Thailand. In 2008, establishing{" "}
          <span>Isigiri kuṭi</span> in the Peak Wilderness Sanctuary of Sri
          Lanka, where he has spent the last 15 years (<span>vassa</span>)
          primarily in solitary retreat.
        </p>
        <p>
          After 25 <span>vassa</span> as a <span>bhikkhu</span>, Ajaan Nico reaffiliated, relocated, and
          settled in another remote mountainous region to pursue practice in the
          forest tradition grounded solely in the principles of <span>dhamma-vinaya</span> in
          their archetypal orientation as a vehicle for mental development
          (<span>citta-bhávaná</span>) and the realisation of final knowledge (<span>aññá</span>).
        </p>
        {/* <p>
          Gosingārāma Mountain Hermitage is establishing solitary retreat
          facilities for well-trained <span>nissayamuttaka bhikkhus</span>{" "}
          (monks freed from dependence) in the <span>kammaṭṭhāna</span> lineage
          of Luang Pu Mun Bhūridatta Mahāthera who wish to cultivate this way of
          practice in remote, mountainous cloud forest.
        </p> */}
        <br />
        <br />
        <div className="quotation">
          <span>
            I do praise bonding with lodgings that are quiet, free of noise,
            with an air of isolation, remote from human beings, and appropriate
            for retreat...
          </span>
          {/* <div><span>Theragāthā v. 245 Yasoja Thera</span></div> */}
        </div>
        <div className="end-mark">
          <hr />
        </div>
      </div>
    </div>
  );
};

export default Author;
