import React from "react";
import Container from "@mui/material/Container";
import { ReprintBookData } from "../../data/BookData";
import { CustomeCard } from "../../components/card/CustomCard";

export const ReprintBookList = () => {
  const renderBookList = () => {
    return ReprintBookData.map((item, index) => (
      <div
        style={{
          margin: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        key={item.title + index}
      >
        <CustomeCard {...item} size={'sm'}/>
        {/* <img
        className="book-cover"
          src={item.image}
          alt="Gosingārāma"
        /> */}
      </div>
    ));
  };
  return (
    <Container maxWidth="lg">
      <div className="book-card-container">{renderBookList()}</div>
    </Container>
  );
};

const Reprints = () => {
  return (
    <div className="App">
      <div className="article">
        <h1>Reprints</h1>
        <p>
          These PDF files are the print & cover files for reprints. The page
          margins of the Third Edition & Abridged Version have been formatted
          for sewn bindings. The Concise Meditation Guide booklet is stapled. No
          permission is required to reprint any of these books from these files
          <span> but only if they are reprinted in their entirety, including the
          copyright page, without changes, additions, or subtractions</span>.
          Otherwise, all rights are reserved.
        </p>
      </div>
      <ReprintBookList />
    </div>
  );
};

export default Reprints;
