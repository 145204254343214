import * as React from 'react';
import Popover from '@mui/material/Popover';
import './style.scss';

export const Info = ({ children, infoText }) => {
  return (
    <div className="popover">
      {children}
      <i
        class="fa fa-info-circle"
        aria-hidden="true"
        data-tooltip={infoText}
      />
    </div>
  );
};



export const MouseOverPopover = ({infoText, children, custom=false, reference}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const renderTooltip = () => {
    if(custom){
        return infoText;
    }
    return <div className='info-text'>{infoText}</div>
  }
  return (
    <span  className="popover">
      <div

        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
      >
       {children}<span className='reference'><sup>{reference}</sup></span>
       {/* <InfoIcon sx={{fontSize: 18}}/> */}
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          // pointerEvents: 'none',
          overflow: 'auto'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        
      >
        {renderTooltip()}
      </Popover>
    </span>
  );
}