import { AbridgedVersion, AbridgedVersionReprint, ConciseEdition, ConciseEditionReprint, SecondEdition, SecondEditionReprint, ThirdEditionReprintSinhala, ThirdEditionSinhala } from "./Descrptions";
import React from 'react';
export const BookData = [
  {
    title: "Third Edition",
    description: <SecondEdition />,
    image: require("../assets/images/bookCovers/cover_Second_Edition.jpg"),
    file: "./pdfs/e-books/third.pdf",
    fileName: 'The Anatomy of Awakening Third Edition eBook Ajaan Nico Jotidhammo',
    fileSize: '20.2MB',
  },
  {
    title: "Abridged Version",
    description: <AbridgedVersion />,
    image: require("../assets/images/bookCovers/cover_Abridged_Version.jpg"),
    file: "./pdfs/e-books/abridged.pdf",
    fileName: 'The Anatomy of Awakening Abridged Version eBook Ajaan Nico Jotidhammo',
    fileSize: '3.3MB',
  },
  {
    title: "Concise Meditation Guide",
    description: <ConciseEdition />,
    image: require("../assets/images/bookCovers/cover_Consise_Version.jpg"),
    file: "./pdfs/e-books/concise.pdf",
    fileName: 'The Anatomy of Awakening Concise Meditation Guide eBook Ajaan Nico Jotidhammo.pdf',
    fileSize: '2.1MB',
  }
];

export const BookDataTranslations = [
  {
    title: "Second Edition (Sinhala)",
    description: <ThirdEditionSinhala />,
    image: require("../assets/images/bookCovers/cover_Consise_Version.jpg"),
    file: "./pdfs/e-books/third-sinhala.pdf",
    fileName: 'The Anatomy of Awakening Second Edition Sinhala eBook Ajaan Nico Jotidhammo.pdf',
    fileSize: '19MB',
  }
]
export const ReprintBookData = [
  {
    title: "Third Edition",
    description: <SecondEditionReprint />,
    image: require("../assets/images/bookCovers/cover_Second_Edition.jpg"),
    file: "./pdfs/reprints/third_edition.zip",
    fileName: 'The Anatomy of Awakening Third Edition_print - Ajaan Nico Jotidhammo',
    fileSize: '25.2MB',
    extension: 'zip'
  },
  {
    title: "Abridged Version",
    description: <AbridgedVersionReprint />,
    image: require("../assets/images/bookCovers/cover_Abridged_Version.jpg"),
    file: "./pdfs/reprints/abridged_version.zip",
    fileName: 'The Anatomy of Awakening Abridged Version_print - Ajaan Nico Jotidhammo',
    fileSize: '4MB',
    extension: 'zip'
  },
  {
    title: "Concise Meditation Guide",
    description: <ConciseEditionReprint />,
    image: require("../assets/images/bookCovers/cover_Consise_Version.jpg"),
    file: "./pdfs/reprints/concise_version.zip",
    fileName: 'The Anatomy of Awakening Concise Meditation Guide_print - Ajaan Nico Jotidhammo',
    fileSize: '3MB',
    extension: 'zip'
  },
  {
    title: "Second Edition (Sinhala)",
    description: <ThirdEditionReprintSinhala />,
    image: require("../assets/images/bookCovers/cover_Consise_Version.jpg"),
    file: "./pdfs/reprints/third_edition(sinhala).zip",
    fileName: 'The Anatomy of Awakening Second Edition_print (Sinhala) - Ajaan Nico Jotidhammo',
    fileSize: '25.5MB',
    extension: 'zip'
  },
];
