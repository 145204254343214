import React from "react";
import Container from "@mui/material/Container";
import { BookData } from "../../data/BookData";
import { CustomeCard } from "../card/CustomCard";

export const BookList = () => {
  const renderBookList = () => {
    return BookData.map((item, index) => (
      <div style={{ margin: "8px", display: "flex" }} key={item.title}>
        <CustomeCard {...item} />
      </div>
    ));
  };
  return (
    <Container maxWidth="lg">
      <div className="book-card-container">{renderBookList()}</div>
    </Container>
  );
};
