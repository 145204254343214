import React from "react";
import { BookDataTranslations } from "../../data/BookData";
import { CustomeCard } from "../../components/card/CustomCard";

const Translations = () => {
  const renderBookList = () => {
    return BookDataTranslations.map((item, index) => (
      <div style={{ margin: "8px", display: "flex" }} key={item.title}>
        <CustomeCard {...item} />
      </div>
    ));
  };
  return (
    <div className="App">
      <div className="article">
        <h1>Translations</h1>
        {/* <p>
          A Thai translation has been broached. Any translations will
          be included here as they become available.
        </p> */}
      </div>
      <div className="book-card-container">{renderBookList()}</div>
    </div>
  );
};

export default Translations;
