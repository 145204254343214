import React from "react";
import "./style.scss";

export const CustomeCard = ({ title, description, image, file, fileName, extension='pdf', size='md', fileSize }) => {
    const downloadBook = () => {
          const element = document.createElement("a");
          element.href = file;
          element.download = `${fileName}.${extension}`;
          element.click();
      }
  return (
    <div className={size === 'md' ? "content-box" : "content-box size"}>
      <h1 className="card-title">{title}</h1>
      <p className="card-content">{description}</p>
      <div>
        <div>
          <span className="see-more" onClick={downloadBook}>Download ({fileSize})</span>
          {/* <span className="see-more">Learn More</span> */}
        </div>
      </div>
    </div>
  );
};