import './fonts.scss';
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navbar";
import { createTheme, ThemeProvider } from "@mui/material";
import Router from "./router";

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#552B13",
      },
    },
  });
  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <Router/>
          <Navbar />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
