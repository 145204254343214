import { Divider } from "@mui/material";
import { BookList } from "../../components/BookList/BookList";
import { BookMark } from "../../components/Bookmark/Bookmark";


const Home = () =>  {
  return (
    <div className="App">
      <BookMark />
      <Divider sx={{ backgroundColor: '#8a701a', width: '70%', marginBottom: '40px'}}/>
       <BookList />

    </div>
  );
}

export default Home;
