import "./style.scss";
export const SecondEdition = () => {
  return (
    <p>
      The Third Edition is a substantially expanded and revised version of{" "}
      <span>The Anatomy of Awakening</span>. It contains extensive footnotes,
      appendices, and supplementary material—forming almost a commentary on the
      root text in places—and may therefore be of interest to specialists or for
      those wishing for more detailed descriptions of this way of practice.
    </p>
  );
};
export const ThirdEditionSinhala = () => {
  return (
    <p>
      The Second Edition (Sinhala) is a substantially expanded and revised version of{" "}
      <span>The Anatomy of Awakening</span>. It contains extensive footnotes,
      appendices, and supplementary material—forming almost a commentary on the
      root text in places—and may therefore be of interest to specialists or for
      those wishing for more detailed descriptions of this way of practice.
    </p>
  );
};
export const AbridgedVersion = () => {
  return (
    <p>
      The Abridged Version comprises the complete root text of{" "}
      <span>The Anatomy of Awakening</span> as it was originally written—without
      footnotes, appendices, or supplementary material—and may be most suitable
      for a reader seeking a complete overview of the path of Buddhist
      meditation but without needing the supporting references, reasoning, and
      details.
    </p>
  );
};
export const ConciseEdition = () => {
  return (
    <p>
      The Concise Meditation Guide contains all of the actual meditation
      instructions in <span>The Anatomy of Awakening</span>—including any
      relevant footnotes and appendices—but does not include the overview of the
      path of insight knowledge or any supplementary material. This booklet
      would be of most benefit for those only requiring a practical meditation
      manual.
    </p>
  );
};
export const SecondEditionReprint = () => {
  return (
    <p>
      <span>The Anatomy of Awakening </span> Third Edition zip print file. Page
      margins formatted for sewn bindings. 108 pages. 22.4MB PDF. Cover file
      included (3.1MB PDF).
    </p>
  );
};
export const ThirdEditionReprintSinhala = () => {
  return (
    <p>
      <span>The Anatomy of Awakening </span> Second Edition (Sinhala) zip print file. Page
      margins formatted for sewn bindings. 108 pages. 22.6MB PDF. Cover file
      included (3MB PDF).
    </p>
  );
};
export const AbridgedVersionReprint = () => {
  return (
    <p>
      <span>The Anatomy of Awakening </span> Abridged Version zip print file.
      Page margins formatted for sewn bindings. 68 pages. 1.6MB PDF. Cover file
      included (2.6MB PDF).
    </p>
  );
};
export const ConciseEditionReprint = () => {
  return (
    <p>
      <span>The Anatomy of Awakening </span> Concise Meditation Guide zip print
      file. Booklet format (stapled spine). 37 pages. 385 kB PDF. Cover file
      Included (2.6MB PDF).
    </p>
  );
};
